<template>
  <div class="role">
    <el-row style="padding-top: 10px;">
      <el-col :span="24">
        <el-button type="success" size="small" @click="AddRole">添加角色</el-button>
      </el-col>
    </el-row>
    <table>
      <thead>
        <tr>
          <th>角色名称</th>
          <th>修改时间</th>
          <th>创建时间</th>
          <th style="width: 160px">操作</th>
          <!-- <th>排位分</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in roleDataList" :key="index">
          <td>{{item.rolename}}</td>
          <td>{{item.update_time}}</td>
          <td>{{item.create_time}}</td>
          <td>
            <i
              style="
                cursor: pointer;
                padding-left: 20px;
                font-size: 18px;
                float: left;
                color: rgb(24, 144, 255);
              "
              title="修改"
              class="el-icon-edit"
              @click="RoleUpdate(item)"
            ></i>
            <i
              style="
                cursor: pointer;
                padding-left: 20px;
                font-size: 18px;
                float: left;
                color: rgb(24, 144, 255);
              "
              title="删除"
              class="el-icon-delete"
              @click="RoleDalete(item)"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog
      :title="AddRoleTitle"
      :visible.sync="AddRoleState"
      width="30%">
        <el-form v-if="AddRoleState " :model="RoleForm" :rules="rules" ref="RoleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="角色名称" prop="rolename">
            <el-input v-model="RoleForm.rolename"></el-input>
          </el-form-item>
          <el-form-item label="权限分配" prop="have_permission_types">
            <el-checkbox v-if="this.ProcessingData.length > 0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
  <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="RoleForm.have_permission_types" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="(item) in ProcessingData" :label="item.id+''" :key="item.val">{{item.val}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CancelAddRole">取 消</el-button>
        <el-button type="primary" @click="ConfirmAddRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "role",
  data(){
    return{
        RoleParams:{
            pageNum:1,
            pageSize:10
        },
      AddRoleState:false,
      RoleForm:{
        rolename:"",
        have_permission_types:[]
      },
      checkAll: false,
      isIndeterminate: false,
      rules:{
        rolename:[
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ]
      },
      ProcessingData:[],
      AllProcessingData:[],
      AddRoleTitle:'新增角色',
    }
  },
  props: {
    roleDataList:{
      type:Array,
      default:null,
    },
    jurisdicDataList:{
      type:Array,
      default:null,
    },
  },
  created(){
  },
  mounted(){
  },
  watch:{
    jurisdicDataList:{
      handler(newVal){
        if(newVal){
          this.ProcessingData = []
          newVal.forEach(item=>{
          for(let key in item){
            this.ProcessingData.push({
              id:key,
              val:item[key]
            })
            this.AllProcessingData.push(key)
          }
        })
        }
      }
    }
  },
  methods:{
    AddRole(){
      this.RoleForm = {
        rolename:"",
        have_permission_types:[]
      }
      this.AddRoleTitle = '新增角色'
      this.AddRoleState = true
    },
    CancelAddRole(){
      this.RoleForm = {
        rolename:"",
        have_permission_types:[]
      }
      this.AddRoleState = false
      this.checkAll = false
      this.isIndeterminate = false
    },
    // 清空角色表单
    EmptyRoleFoem(){
      this.RoleForm = {
        rolename:"",
        have_permission_types:[]
      }
      this.AddRoleState = false
      this.checkAll = false
      this.isIndeterminate = false
    },
    ConfirmAddRole(){
      this.$refs['RoleForm'].validate((valid) => {
        if (valid) {
          let CopeData = JSON.parse(JSON.stringify(this.RoleForm))
          let ParamData = {
            rolename : CopeData.rolename,
            have_permission_types:[]
          }
          CopeData.have_permission_types.forEach(item=>{
            this.ProcessingData.forEach(items=>{
              if(item == items.id){
                ParamData.have_permission_types.push({[items.id]:items.val})
              }
            })
          })
          if(this.RoleForm.role_id){
            ParamData.role_id = this.RoleForm.role_id
            this.$emit('UpadteRole',ParamData);
          }else{
            this.$emit('AddRole',ParamData);
          }
        } else {
          return false;
        }
      });
    },
    handleCheckAllChange(val) {
        this.RoleForm.have_permission_types = val ? this.AllProcessingData : [];
        this.isIndeterminate = false;
      },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.AllProcessingData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.AllProcessingData.length;
    },
    RoleUpdate(item){
      this.AddRoleTitle = '修改角色'
      let CopeDataList = JSON.parse(JSON.stringify(item.have_permission_types))
      this.RoleForm.have_permission_types = []
      this.RoleForm.rolename = item.rolename
      this.RoleForm.role_id = item.role_id
      CopeDataList.forEach(item=>{
        for(let key in item){
          this.RoleForm.have_permission_types.push(key)
        }
      })
      this.handleCheckedCitiesChange(this.RoleForm.have_permission_types)
      this.AddRoleState = true
    },
    // 删除角色
    RoleDalete(item){
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('RoleDalete',item);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.role {
  table {
    font-size: 14px;
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    border-collapse: separate;
    border-spacing: 0;
    thead {
      box-shadow: none;
      tr {
        th {
          cursor: auto;
          background: #f9fafb;
          text-align: inherit;
          color: rgba(0, 0, 0, 0.87);
          padding: 0.92857143em 0.78571429em;
          vertical-align: inherit;
          font-style: none;
          font-weight: 700;
          text-transform: none;
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
          border-left: 1px solid rgba(34, 36, 38, 0.1);
        }
        th:last-child {
          width: 200px;
        }
      }
    }
    tbody {
      tr {
        font-size: 13px;
        td {
          padding: 0.78571429em 0.78571429em;
          text-align: inherit;
          border-left: 1px solid rgba(34, 36, 38, 0.1);
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
        }
        td:last-child {
          width: 200px;
        }
      }
    }
  }
}
</style>