<template>
  <div class="ToExamine">
    <el-row>
      <el-col :span="4">
        <el-input
          size="mini"
          v-model="ToExamineParams.keywords"
          @keyup.enter.native="getsearchToExamine"
          clearable
          placeholder="请输入关键字"
        ></el-input>
      </el-col>
      <el-col :span="2" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchToExamine">搜 索</el-button>
      </el-col>
      <el-col style="color:red;">
          本阶段为审核表单数据功能页面，用户学生已提交表单数据<br>
          通过：表单数据提交到生成管理页面<br>
          不通过：表单数据将被删除，用户则需要从新提交<br>
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%; margin-top: 20px">
      <el-table-column fixed type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-row
              v-for="(iten, index) in props.row.apply_schools"
              :key="index"
            >
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item :label="'申请学校(' + Number(index + 1) + ')'">
                  <span>{{ iten.apply_school }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item label="申请专业">
                  <span>{{ iten.specialty }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号" >
      </el-table-column>
      <el-table-column fixed prop="realname" label="姓名" >
      </el-table-column>
      <el-table-column fixed prop="gender" label="性别" >
        <template slot-scope="scope">
          {{ scope.row.gender == "0" ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="student_num" label="学号" >
      </el-table-column>
      <el-table-column prop="email" label="邮箱地址" >
      </el-table-column>
      <el-table-column prop="average_gpa" label="平均GPA"> </el-table-column>
      <el-table-column prop="specialist_rank" label="专业排名">
      </el-table-column>
      <!-- <el-table-column
        prop="is_credit_student"
        label="是否是信用管理专业学生"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.is_credit_student == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="isin_my_class" label="是否上过我的课" width="150">
        <template slot-scope="scope">
          {{ scope.row.isin_my_class == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_finance_school"
        label="是否是金融学院学生"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.is_finance_school == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_collaborate_project"
        label="是否有合作过研究或项目"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.is_collaborate_project == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="season" label="开始上我的课的季度" width="150">
        <template slot-scope="scope">
          {{ translateSeason(scope.row.season) }}
        </template>
      </el-table-column>
      <el-table-column prop="stu_year" label="开始上我的课的时间" width="150">
        <template slot-scope="scope"> {{ scope.row.stu_year }}年 </template>
      </el-table-column>
      <el-table-column prop="is_dissert" label="是否写课程论文" width="150">
        <template slot-scope="scope">
          {{ scope.row.is_dissert == "0" ? "否" : "是" }}
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="paper_title" label="所写论文名称" width="150">
      </el-table-column>
      <el-table-column prop="body_text_1" label="正文一" width="200">
      </el-table-column>
      <el-table-column prop="body_text_2" label="正文二" width="200">
      </el-table-column>
      <el-table-column prop="body_text_3" label="正文三" width="200">
      </el-table-column> -->
      <el-table-column fixed="right" prop="create_time" label="申请时间" width="150">
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="220">
        <template slot-scope="scope">
            <el-button
            type="warning"
            size="mini"
            @click="details(scope.row)"
            plain
            >详情</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="ToExamine(1, scope.row.form_id)"
            plain
            >通过</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="ToExamine(0, scope.row.form_id)"
            plain
            >不通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="total"
      :page.sync="ToExamineParams.pageNum"
      :limit.sync="ToExamineParams.pageSize"
      @pagination="getsearchToExamine"
    />

    <!-- 详情查看 -->
    <el-dialog
  title="详情查看"
  :visible.sync="detailsState"
  width="60%"
  >
    <el-form
        ref="form"
        class="FormTable"
        :model="form"
        label-position="left"
        label-width="260px"
      >
        <el-row style="border-top: 1px solid #000;border-right:1px solid #000;">
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              姓名
            </div>
            <div class="detailCol">
              {{form.realname}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              学号
            </div>
            <div class="detailCol">
              {{form.student_num}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              性别
            </div>
            <div class="detailCol">
              {{form.gender == '1' ? '男' : '女'}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              邮箱
            </div>
            <div class="detailCol">
              {{form.email}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              平均GPA
            </div>
            <div class="detailCol">
              {{form.average_gpa}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              准备申请几个学校
            </div>
            <div class="detailCol">
              {{form.apply_school_num}}
            </div>
          </el-col>
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%;">
              专业排名(前Top)
            </div>
            <div class="detailCol">
              {{form.specialist_rank == '1' ? '1%' : form.specialist_rank == '5' ? '5%' : form.specialist_rank == '10' ? '10%' : form.specialist_rank == '30' ? '10% - 30%' : form.specialist_rank == '50' ? '30% - 50%' : form.specialist_rank == '100' ? '50% - 100%' : ''}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              专业名称
            </div>
            <div class="detailCol">
              <!-- {{form.is_credit_student == '1' ? '是' : '否'}} -->
              {{form.is_credit_student}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              是否上过我的课
            </div>
            <div class="detailCol">
              {{form.isin_my_class == '1' ? '是' : '否'}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              学院名称
            </div>
            <div class="detailCol">
              <!-- {{form.is_finance_school == '1' ? '是' : '否'}} -->
              {{form.is_finance_school}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              是否合作过研究或项目
            </div>
            <div class="detailCol">
              {{form.is_collaborate_project == '1' ? '是' : '否'}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              开始上我的课的季度
            </div>
            <div class="detailCol">
              {{translateSeason(form.season)}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              开始上我的课的时间
            </div>
            <div class="detailCol">
              {{form.stu_year}}年
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              是否写课程论文
            </div>
            <div class="detailCol">
              {{form.is_dissert == '1' ? '是' : '否'}}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">
              所写论文名称
            </div>
            <div class="detailCol">
              {{form.is_dissert == 1 ? form.paper_title : ''}}
            </div>
          </el-col>
        </el-row>
        <template v-if="form.apply_school_num > 0">
          <el-row v-for="(item, index) in form.apply_school_num" :key="index" style="border-right:1px solid;">
            <el-col :span="12" class="detailColAll">
              <div class="detailColTitle">
                申请学校{{(index + 1)}}
              </div>
              <div class="detailCol">
                {{form.apply_schools[index].apply_school}}
              </div>
            </el-col>
            <el-col :span="12" class="detailColAll">
              <div class="detailColTitle">
                申请专业{{(index + 1)}}
              </div>
              <div class="detailCol">
                {{form.apply_schools[index].specialty}}
              </div>
            </el-col>
          </el-row>
        </template>
        <el-row style="border-right:1px solid;">
          <el-col :span="24" class="detailColAll">
              <div class="detailColTitle" style="width: 20%;">
                正文一
              </div>
              <div class="detailCol">
                {{form.body_text_1}}
              </div>
          </el-col>
        </el-row>
        <el-row style="border-right:1px solid;"> 
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%;">
                正文二
              </div>
              <div class="detailCol">
                {{form.body_text_2}}
              </div>
          </el-col>
        </el-row>
        <el-row style="border-right:1px solid;">
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%;">
                正文三
              </div>
              <div class="detailCol">
                {{form.body_text_3}}
              </div>
          </el-col>
        </el-row>
      </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="detailscancel">取 消</el-button>
    <!-- <el-button type="primary" @click="detailsconfirm">确 定</el-button> -->
  </span>
</el-dialog>
  </div>
</template>

<script>
import {
  check_apply,
  search_submit_form,
} from "../../../api/Administration.js";
export default {
  data() {
    return {
      ToExamineParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        status: [0],
      },
      tableData: [],
      total: 0,
      form:{

      },
      detailsState:false,
      pickerOptions:{
        //disabled为函数，返回值为布尔值，
          disabledDate: (time) => {
            let minYear = new Date().getFullYear() - 30          
            return time> Date.now() || time.getTime() < new Date(JSON.stringify(minYear))
          }
      },
      SeasonArr:[
          {
              val:'spring',
              label:'春'
          },
          {
              val:'summer',
              label:'夏'
          },
          {
              val:'autumn',
              label:'秋'
          },
          {
              val:'winter',
              label:'冬'
          }
      ],
    };
  },
  created() {
    this.getsearchToExamine();
  },
  methods: {
    // 获取为审核的表单数据列表
    getsearchToExamine() {
      search_submit_form(this.ToExamineParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 翻译开始上我的课的季度
    translateSeason(item) {
      let Name = "";
      switch (item) {
        case "spring":
          Name = "春";
          break;
        case "summer":
          Name = "夏";
          break;
        case "autumn":
          Name = "秋";
          break;
        case "winter":
          Name = "冬";
          break;
        default:
          Name = "";
      }
      return Name;
    },
    // 详情查看
    details(item){
      this.form = JSON.parse(JSON.stringify(item))
      this.detailsState = true
    },
    // 详情取消
    detailscancel(){
      this.form = {}
      this.detailsState = false
    },
    // 审核操作
    ToExamine(item, id) {
      this.$confirm("此操作将更改审核状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("app/CHANGE_LOADING", true);
          check_apply({ form_id: id, is_pass: item })
            .then((res) => {
              if (res.code == 200) {
                this.getsearchToExamine();
                this.$store.commit("app/CHANGE_LOADING", false);
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }else{
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(() => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style>
.detailColAll{
  box-sizing: border-box;
  display: flex;
  line-height: 40px;
  /* border: 1px solid #000; */
  /* border-right: 1px solid #000; */
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  /* border-top: 1px solid #000; */
}
.detailColTitle {
  border-right: 1px solid #000;
  /* background-color: #BFBFBF; */
  width: 40%;
  font-weight: 600;
  padding-left: 30px;
  }
.detailCol {
  /* border-right: 1px solid #000; */
  /* background-color: #BFBFBF; */
  width: 60%;
  color: #000;
  padding-left: 30px;
}
</style>