<template>
  <div class="ManagementCenter">
    <div class="ManagementCenter-box">
      <div class="ManagementCenter-centent page-m">
        <!-- 左部导航栏 -->
        <div class="ManagementCenter-centent-left">
          <ul>
            <li
              v-if="EntryPermissionsState.indexOf(1) != -1"
              :class="EntranceChangeState == 1 ? 'about-list-curr' : ''"
              @click="OclickEntrance(1)"
            >
              角色列表
            </li>
            <li
              v-if="EntryPermissionsState.indexOf(2) != -1"
              :class="EntranceChangeState == 2 ? 'about-list-curr' : ''"
              @click="OclickEntrance(2)"
            >
              用户管理
            </li>
            <li
              v-if="EntryPermissionsState.indexOf(3) != -1"
              :class="EntranceChangeState == 3 ? 'about-list-curr' : ''"
              @click="OclickEntrance(3)"
            >
              模块管理
            </li>
            <li
              v-if="EntryPermissionsState.indexOf(5) != -1"
              :class="EntranceChangeState == 5 ? 'about-list-curr' : ''"
              @click="OclickEntrance(5)"
            >
              审核管理
            </li>
            <li
              v-if="EntryPermissionsState.indexOf(6) != -1"
              :class="EntranceChangeState == 6 ? 'about-list-curr' : ''"
              @click="OclickEntrance(6)"
            >
              生成管理
            </li>
            <li
              v-if="EntryPermissionsState.indexOf(4) != -1"
              :class="EntranceChangeState == 4 ? 'about-list-curr' : ''"
              @click="OclickEntrance(4)"
            >
              推荐信管理
            </li>
          </ul>
        </div>
        <!-- 右部分主体内容 -->
        <div class="ManagementCenter-centent-right">
          <!-- 角色列表 -->
          <div
            v-if="EntranceChangeState == 1"
            class="ManagementCenter-centent-user"
          >
            <div class="user_list_details">角色列表</div>
            <Role
              @RoleDalete="RoleDalete"
              ref="RoleId"
              @UpadteRole="UpadteRole"
              @AddRole="AddRole"
              :roleDataList="roleDataList"
              :jurisdicDataList="jurisdicDataList"
            />
          </div>

          <div
            v-if="EntranceChangeState == 2"
            class="ManagementCenter-centent-user"
          >
            <User ref="UserId" />
          </div>
          <div
            v-if="EntranceChangeState == 3"
            class="ManagementCenter-centent-user"
          >
            <Modular ref="ModularId" />
          </div>
          <div
            v-if="EntranceChangeState == 4"
            class="ManagementCenter-centent-user"
          >
            <Recommenda ref="RecommendationId" />
          </div>
          <div
            v-if="EntranceChangeState == 5"
            class="ManagementCenter-centent-user"
          >
            <ToExamine ref="ToExamineId" />
          </div>
          <div
            v-if="EntranceChangeState == 6"
            class="ManagementCenter-centent-user"
          >
            <FormMenten ref="FormMentenId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  search_role,
  add_role,
  delete_role,
  update_role,
  search_all_permission,
} from "../../api/Administration.js";
import { getInfo } from "../../api/user.js";
import { getToken } from "../../utils/auth";
import Role from "./assembly/role.vue";
import User from "./assembly/user.vue";
import Modular from "./assembly/modular.vue";
import Recommenda from "./assembly/recommenda.vue";
import ToExamine from "./assembly/ToExamine.vue";
import FormMenten from "./assembly/FormMenten.vue";
export default {
  name: "ManagementCenter",
  components: {
    Role,
    User,
    Modular,
    Recommenda,
    ToExamine,
    FormMenten,
  },
  data() {
    return {
      EntranceChangeState: 1,
      roleDataList: [], //角色数据列表
      jurisdicDataList: [], //权限数据列表
      EntryPermissionsState: [], //入口权限
    };
  },
  created() {
    // 获取权限
    if (getToken()) {
      getInfo().then((res) => {
        // 从新获取权限判断入口权限存放到VueX
        // 从新获判断当前用户是否为管理员状态存放到VueX
        var EntryPermissions = [];
        if (res.data.permission_info[0] != 0) {
          if (res.data.permission_info[0] == 99) {
            this.$store.commit("SET_Login", true);
            this.$store.commit("SET_Administrators", true);
            this.$store.commit("SET_EntryPermissions", [1, 2, 3, 4, 5, 6]); //超级管理员附有所有入口权限
            this.EntryPermissionsState = [1, 2, 3, 4, 5, 6];
            if (this.EntryPermissionsState.length > 0) {
              if (this.EntryPermissionsState[0] == 1) {
                this.getroleDataList();
                this.GetjurisdicDataList();
              } else {
                this.EntranceChangeState = this.EntryPermissionsState[0];
              }
            }
          } else {
            if (res.data.is_teacher == 1) {
              EntryPermissions.push(3);
              EntryPermissions.push(4);
              EntryPermissions.push(5);
              EntryPermissions.push(6);
            }
            if (res.data.permission_info.includes("查看用户")) {
              EntryPermissions.push(2);
            }
            if (res.data.permission_info.includes("查看模板")) {
              EntryPermissions.push(3);
            }
            if (res.data.permission_info.includes("查看表单")) {
              EntryPermissions.push(6);
            }
            if (res.data.permission_info.includes("审核推荐信")) {
              EntryPermissions.push(5);
            }
            if (
              res.data.permission_info.includes("生成推荐信") ||
              res.data.permission_info.includes("查看推荐信")
            ) {
              EntryPermissions.push(4);
            }
            this.$store.commit("SET_Administrators", true);
            this.$store.commit("SET_Login", true);
            this.EntryPermissionsState = [...new Set(EntryPermissions)];
            if (this.EntryPermissionsState.length > 0) {
              if (this.EntryPermissionsState[0] == 1) {
                this.getroleDataList();
                this.GetjurisdicDataList();
              } else {
                this.EntranceChangeState = this.EntryPermissionsState[0];
              }
            }
          }
        } else if (res.data.is_teacher == 1) {
          EntryPermissions.push(3);
          EntryPermissions.push(4);
          EntryPermissions.push(5);
          EntryPermissions.push(6);
          this.EntryPermissionsState = [...new Set(EntryPermissions)];
          if (this.EntryPermissionsState.length > 0) {
            if (this.EntryPermissionsState[0] == 1) {
              this.getroleDataList();
              this.GetjurisdicDataList();
            } else {
              this.EntranceChangeState = this.EntryPermissionsState[0];
            }
          }
        }
      });
    }
  },
  watch: {
    EntranceChangeState(newVal) {
      if (getToken()) {
        if (newVal == 1) {
          this.getroleDataList();
          this.GetjurisdicDataList();
        }
      }
    },
    "$store.state.user.EntryPermissions"(val) {
      console.log(val, 56);
      if (val.length == 0) {
        // ManagementCenter
        this.$destroy();
      }
    },
    // "$store.state.user.EntryPermissions"(val) {
    //   if (val.length > 0) {
    //     if (getToken()) {
    //       getInfo().then((res) => {
    //         // 从新获取权限判断入口权限存放到VueX
    //         // 从新获判断当前用户是否为管理员状态存放到VueX
    //         if (res.data.permission_info[0] != 0) {
    //           if (res.data.permission_info[0] == 99) {
    //             this.$store.commit("SET_Login", true);
    //             this.$store.commit("SET_Administrators", true);
    //             this.$store.commit("SET_EntryPermissions", [1, 2, 3, 4, 5, 6]); //超级管理员附有所有入口权限
    //             this.EntryPermissionsState = [1, 2, 3, 4, 5, 6];
    //             if (this.EntryPermissionsState.length > 0) {
    //               if (this.EntryPermissionsState[0] == 1) {
    //                 this.getroleDataList();
    //                 this.GetjurisdicDataList();
    //               } else {
    //                 this.EntranceChangeState = this.EntryPermissionsState[0];
    //               }
    //             }
    //           } else {
    //             let EntryPermissions = [];
    //             if (res.data.permission_info.includes("查看用户")) {
    //               EntryPermissions.push(2);
    //             }
    //             if (res.data.permission_info.includes("查看模板")) {
    //               EntryPermissions.push(3);
    //             }
    //             if (res.data.permission_info.includes("查看表单")) {
    //               EntryPermissions.push(6);
    //             }
    //             if (res.data.permission_info.includes("审核推荐信")) {
    //               EntryPermissions.push(5);
    //             }
    //             if (
    //               res.data.permission_info.includes("生成推荐信") ||
    //               res.data.permission_info.includes("查看推荐信")
    //             ) {
    //               EntryPermissions.push(4);
    //             }
    //             this.$store.commit("SET_Administrators", false);
    //             this.$store.commit("SET_Login", true);
    //             this.EntryPermissionsState = EntryPermissions;
    //             if (this.EntryPermissionsState.length > 0) {
    //               if (this.EntryPermissionsState[0] == 1) {
    //                 this.getroleDataList();
    //                 this.GetjurisdicDataList();
    //               } else {
    //                 this.EntranceChangeState = this.EntryPermissionsState[0];
    //               }
    //             }
    //           }
    //         }
    //       });
    //     }
    //   }
    // },
  },
  methods: {
    // 切换导航栏显示状态
    OclickEntrance(item) {
      this.EntranceChangeState = item;
    },
    // 获取角色列表
    getroleDataList() {
      search_role()
        .then((res) => {
          if (res.code == 200) {
            this.roleDataList = res.info_list;
          }
        })
        .catch(() => {
          this.roleDataList = [];
        });
    },
    // 获取数据权限列表
    GetjurisdicDataList() {
      search_all_permission().then((res) => {
        if (res.code == 200) {
          this.jurisdicDataList = res.data;
        }
      });
    },
    // 删除角色
    RoleDalete(item) {
      delete_role({ role_id: item.role_id }).then((res) => {
        if (res.code == 200) {
          // 获取角色列表
          this.getroleDataList();
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
    },
    // 修改角色
    UpadteRole(item) {
      update_role(item)
        .then((res) => {
          if (res.code == 200) {
            // 获取角色列表
            this.getroleDataList();
            this.$refs.RoleId.EmptyRoleFoem();
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "修改失败",
          });
          this.$refs.RoleId.EmptyRoleFoem();
        });
    },
    // 添加角色
    AddRole(item) {
      add_role(item)
        .then((res) => {
          if (res.code == 200) {
            // 获取角色列表
            this.getroleDataList();
            this.$refs.RoleId.EmptyRoleFoem();
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "新增失败",
          });
          this.$refs.RoleId.EmptyRoleFoem();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.hide .el-upload--picture-card {
  display: none;
}
@import "./index.scss";
@media (min-width: 1400px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 1700px;
  }
}
@media (min-width: 992px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 90%;
    // width: 900px;
    // width: 1700px;
  }
}
</style>