<template>
  <div class="Recommenda">
    <el-row>
      <el-col :span="4">
        <el-input
          size="mini"
          v-model="RecommendaParams.keywords"
          @keyup.enter.native="getsearchRecommenda"
          clearable
          placeholder="请输入推荐信关键字"
        ></el-input>
      </el-col>
      <el-col :span="2" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchRecommenda"
          >搜 索</el-button
        >
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button
          size="mini"
          type="danger"
          icon="el-icon-delete"
          :disabled="multiple"
          @click="DeleteRecommendationLetter"
          >删除</el-button
        >
      </el-col>
    </el-row>

    <el-table
      @selection-change="handleSelectionChange"
      :data="tableData"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column fixed type="selection" width="30" />
      <el-table-column prop="realname" label="真实姓名"> </el-table-column>
      <el-table-column prop="username" label="手机号"> </el-table-column>
      <el-table-column prop="apply_school" label="申请学校"> </el-table-column>
      <el-table-column prop="specialty" label="申请专业"> </el-table-column>
      <!-- <el-table-column prop="letter_url" label="推荐信地址"> </el-table-column>
      <el-table-column prop="file_type" label="文件类型"> </el-table-column> -->
      <el-table-column prop="letter_status" label="推荐信状态">
        <template slot-scope="scope">
          {{ scope.row.letter_status == "0" ? "审批中" : "已生成" || "" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="修改时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="160"
      ></el-table-column>
      <el-table-column prop="address" label="操作" width="412.5px">
        <template slot-scope="scope">
          <el-upload
            class="upload-demo"
            style="display: initial"
            :show-file-list="false"
            :action="UrlApi"
            :on-change="handleChange"
            :on-success="handlesuccess"
            :on-error="handleError"
            accept=".doc,.docx"
          >
            <el-button
              @click="UploadRecommendationLetter(scope.row.letter_id)"
              type="primary"
              size="mini"
              plain
              >上传推荐信</el-button
            >
          </el-upload>
          <el-button
            style="margin-left:10px;"
            type="success"
            size="mini"
            @click="download(scope.row.letter_url_pdf, 'pdf')"
            plain
            >PDF</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="download(scope.row.letter_url_word, 'word')"
            plain
            >WORD</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="preview(scope.row.letter_url_pdf)"
            plain
            >预览</el-button
          >
          <el-button
            @click="DeleteRecommendationLetter(scope.row)"
            type="danger"
            size="mini"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="RecommendaParams.pageNum"
      :limit.sync="RecommendaParams.pageSize"
      @pagination="getsearchRecommenda"
    />
  </div>
</template>

<script>
import {
  search_letter,
  upload_letter,
  delete_letter
} from "../../../api/Administration.js";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { publicbaseURLs } from "@/utils/request";
export default {
  data() {
    return {
      RecommendaParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10
      },
      ids: [],
      tableData: [],
      multiple: true,
      total: 0,
      Files: [],
      FilesId: "",
      UrlApi: publicbaseURLs() + "/upload_letter"
    };
  },
  created() {
    this.getsearchRecommenda();
  },
  methods: {
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.letter_id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    // 获取推荐信列表数据
    getsearchRecommenda() {
      search_letter(this.RecommendaParams).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 下载文件
    download(url, type) {
      if (type == "pdf") {
        window.location.href = url + "/1";
        return;
      } else {
        window.location.href = url + "/1";
        return;
      }
    },
    // 预览
    preview(url) {
      window.open(url + "/0");
    },

    UploadRecommendationLetter(id) {
      console.log(id);
      this.FilesId = id;
    },
    // 选择文件
    handleChange(row) {
      this.Files = row.raw;
    },
    // 上传成功
    handlesuccess() {
      this.$store.commit("app/CHANGE_LOADING", true);
      var FileData = new FormData();
      FileData.append("file", this.Files);
      FileData.append("letter_id", this.FilesId);
      FileData.append("token", getToken());
      //   upload_letter(FileData).then(res=>{
      //       if(res.code == 200){
      //       this.$message({
      //         type: "success",
      //         message: res.msg,
      //       });
      //       this.$store.commit("app/CHANGE_LOADING", false);
      //     }
      //   }).catch(err=>{
      //       this.$store.commit("app/CHANGE_LOADING", false);
      //   })
      axios({
        url: this.UrlApi,
        method: "post",
        data: FileData,
        headers: {
          Authorization: getToken(),
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: res.data.msg
            });

            this.$store.commit("app/CHANGE_LOADING", false);
          }
        })
        .catch(err => {
          this.$store.commit("app/CHANGE_LOADING", false);
        });
    },
    // 上传失败
    handleError() {
      this.$message({
        type: "error",
        message: "上传失败"
      });
      this.Files = [];
    },
    //删除推荐信
    DeleteRecommendationLetter(item) {
      this.$confirm("此操作将永久删除该推荐信, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var id;
          if (item.letter_id) {
            id = [item.letter_id];
          } else {
            id = this.ids;
          }
          delete_letter({
            letter_ids: id
          }).then(res => {
            if (res.code == 200) {
              this.getsearchRecommenda();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>

<style></style>
