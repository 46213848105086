<template>
  <div class="user">
    <el-row>
      <el-col :span="4">
        <el-input
          size="mini"
          v-model="UserParams.keywords"
          @keyup.enter.native="getsearchStudent"
          clearable
          placeholder="请输入用户关键字"
        ></el-input>
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchStudent"
          >搜 索</el-button
        >
      </el-col>
      <el-col :span="2" style="padding-left: 20px">
        <el-button size="mini" type="success" @click="AddUser"
          >添加用户</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%; margin-top: 20px">
      <el-table-column prop="username" label="用户名"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"> </el-table-column>
      <el-table-column prop="" label="姓名">
        <template slot-scope="scope">
          <!-- {{ TranslationRealname(scope.row.username) }} -->
          {{ scope.row.realname }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_teacher"
        label="身份"
        :formatter="is_teacherFormat"
      >
      </el-table-column>
      <el-table-column prop="role_id" label="角色">
        <template slot-scope="scope">
          {{ TranslationRole(scope.row.role_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? "启用" : "禁用" }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <i
            style="
              cursor: pointer;
              padding-left: 20px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="修改"
            class="el-icon-edit"
            @click="UserUpdate(scope.row)"
          ></i>
          <i
            style="
              cursor: pointer;
              padding-left: 20px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="删除"
            class="el-icon-delete"
            @click="UserDalete(scope.row)"
          ></i>
          <i
            style="
              cursor: pointer;
              padding-left: 20px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="重置"
            class="el-icon-refresh"
            @click="Reset(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="total"
      :page.sync="UserParams.pageNum"
      :limit.sync="UserParams.pageSize"
      @pagination="getsearchStudent"
    />

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="forms" :model="form" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input
            :disabled="dialogTitle == '修改用户' ? true : false"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dialogTitle == '添加用户'"
          label="密码"
          prop="password"
        >
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select
            :disabled="form.role_id == 1 ? true : false"
            style="width: 100%"
            v-model="form.role_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleDataList"
              :key="item.role_id"
              :label="item.rolename"
              :value="item.role_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="is_teacher">
          <el-switch
            v-model="form.is_teacher"
            active-text="老师"
            :active-value="1"
            :inactive-value="0"
            inactive-text="学生"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          style="text-align: left"
          label="是否启用用户"
          prop="seconds"
        >
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchUser,
  search_role,
  admin_update_user,
  admin_register,
  delete_user,
  reset_passwords,
  teacher_list,
} from "../../../api/Administration.js";
export default {
  data() {
    return {
      UserParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
      },
      form: {
        status: 1,
      },
      teacherList: [],
      tableData: [],
      roleDataList: [], //角色列表数据
      total: 0,
      dialogTitle: "添加用户",
      dialogVisible: false,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
      Updaterules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        // realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        role_id: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择启用状态",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getsearchStudent();
    this.getroleDataList();
    this.getsearchteacher();
  },
  methods: {
    //身份判断
    is_teacherFormat(row, column) {
      if (row.is_teacher === 1) {
        return "老师";
      } else {
        return "学生";
      }
    },
    // 获取角色列表
    getroleDataList() {
      search_role()
        .then((res) => {
          if (res.code == 200) {
            res.info_list.push({
              rolename: "超级管理员",
              role_id: "1",
            });
            res.info_list.push({
              rolename: "普通用户",
              role_id: "2",
            });
            this.roleDataList = res.info_list;
            // let obj = {};
            // let peon = person.reduce((cur,next) => {
            //     obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
            //     return cur;
            // },[])
            // this.roleDataList
          }
        })
        .catch(() => {
          this.roleDataList = [];
        });
    },
    // 获取教师数据列表
    getsearchteacher() {
      teacher_list().then((res) => {
        this.teacherList = res.data;
      });
    },
    // 重置密码
    Reset(item) {
      this.$confirm("此操作将密码重置为123, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reset_passwords({
            username: item.username,
          }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    // 获取用户列表数据
    getsearchStudent() {
      searchUser(this.UserParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 翻译角色名称
    TranslationRole(id) {
      let Name = "";
      // if(id == 1){
      //     Name = '超级管理员'
      //     return Name
      // }
      // if(id == 2){
      //     Name = '普通用户'
      //     return Name
      // }
      this.roleDataList.forEach((item) => {
        if (item.role_id == id) {
          Name = item.rolename;
        }
        // else {
        //   Name = item.rolename;
        // }
      });
      return Name;
    },
    //老师字段
    TranslationRealname(id) {
      var RealnaneName = "";
      this.teacherList.forEach((item) => {
        if (item.teacher_id == id) {
          RealnaneName = item.realname;
        }
      });
      return RealnaneName;
    },
    // 取消提交
    cancel() {
      this.dialogTitle = "添加用户";
      this.form = {};
      this.getsearchStudent();
      this.dialogVisible = false;
    },
    // 确认提交
    confirm() {
      if (this.form.password) {
        admin_register({ register_dict: this.form }).then((res) => {
          if (res.code == 200) {
            this.cancel();
          }
        });
      } else {
        admin_update_user({ update_info: this.form }).then((res) => {
          if (res.code == 200) {
            this.cancel();
          }
        });
      }
    },
    // 添加用户
    AddUser() {
      this.form = {
        status: 1,
      };
      this.dialogTitle = "添加用户";
      this.dialogVisible = true;
    },
    // 修改用户
    UserUpdate(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.form.role_id = this.form.role_id + "";
      this.dialogTitle = "修改用户";
      this.dialogVisible = true;
      this.$forceUpdate();
    },
    // 删除用户
    UserDalete(item) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delete_user({
            username: item.username,
          }).then((res) => {
            if (res.code == 200) {
              this.getsearchStudent();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
