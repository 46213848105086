<template>
  <div class="FormMenten">
    <el-row>
      <el-col :span="4">
        <el-select
          size="mini"
          style="width: 100%"
          @change="StatusChange"
          v-model="ToExamineParams.status"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.val"
            :label="item.label"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" style="padding-left: 20px">
        <el-input
          size="mini"
          v-model="ToExamineParams.keywords"
          @keyup.enter.native="getsearchToExamine"
          clearable
          placeholder="请输入推荐信关键字"
        ></el-input>
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchToExamine"
          >搜 索</el-button
        >
      </el-col>
      <!-- <el-col :span="1.5" style="padding-left: 20px">
        <el-button
          type="success"
          icon="el-icon-edit"
          :disabled="single"
          @click="handleUpdate"
          >修改</el-button
        >
      </el-col> -->
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button
          size="mini"
          type="danger"
          icon="el-icon-delete"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </el-col>
      <el-col style="color:red;">
        本阶段为生成推荐信功能页面
        <i
          style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 14px;
              color: rgb(24, 144, 255);
            "
          title="生成"
          class="el-icon-document-checked"
        ></i>
        为生成推荐信功能； 如需要调整可通过
        <i
          style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 14px;
              color: rgb(24, 144, 255);
            "
          title="修改"
          class="el-icon-edit"
          @click="handleUpdate(scope.row)"
        ></i>
        修改功能进行修改后再生成新推荐信（覆盖原有推荐信）
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column fixed type="selection" width="30" />
      <el-table-column fixed type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-row
              v-for="(iten, index) in props.row.apply_schools"
              :key="index"
            >
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item :label="'申请学校(' + Number(index + 1) + ')'">
                  <span>{{ iten.apply_school }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item label="申请专业">
                  <span>{{ iten.specialty }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号" width="150">
      </el-table-column>
      <el-table-column fixed prop="realname" label="姓名" width="100">
      </el-table-column>
      <el-table-column fixed prop="gender" label="性别" width="50">
        <template slot-scope="scope">
          {{ scope.row.gender == "0" ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="student_num" label="学号" width="120">
      </el-table-column>
      <el-table-column prop="email" label="邮箱地址" width="150">
      </el-table-column>
      <el-table-column prop="average_gpa" label="平均GPA"> </el-table-column>
      <el-table-column prop="specialist_rank" label="专业排名">
      </el-table-column>
      <el-table-column prop="is_credit_student" label="专业名称" width="180">
        <template slot-scope="scope">
          <!-- {{ scope.row.is_credit_student == "0" ? "否" : "是" }} -->
          {{ scope.row.is_credit_student }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="isin_my_class" label="是否上过我的课" width="150">
        <template slot-scope="scope">
          {{ scope.row.isin_my_class == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_finance_school"
        label="学院名称"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.is_finance_school}}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_collaborate_project"
        label="是否有合作过研究或项目"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.is_collaborate_project == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="season" label="开始上我的课的季度" width="150">
        <template slot-scope="scope">
          {{ translateSeason(scope.row.season) }}
        </template>
      </el-table-column>
      <el-table-column prop="stu_year" label="开始上我的课的时间" width="150">
        <template slot-scope="scope"> {{ scope.row.stu_year }}年 </template>
      </el-table-column>
      <el-table-column prop="is_dissert" label="是否写课程论文" width="150">
        <template slot-scope="scope">
          {{ scope.row.is_dissert == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="paper_title" label="所写论文名称" width="150">
      </el-table-column>
      <el-table-column
        prop="body_text_1"
        label="正文一"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="body_text_2"
        label="正文二"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="body_text_3"
        label="正文三"
        width="200"
      ></el-table-column> -->
      <el-table-column fixed="right" label="状态" width="120">
        <template slot-scope="scope">
          <el-button
            disabled
            v-if="scope.row.status == 1"
            size="mini"
            type="primary"
            plain
            >已审核</el-button
          >
          <el-button
            disabled
            v-if="scope.row.status == 2"
            size="mini"
            type="success"
            plain
            >已生成</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        prop="create_time"
        label="审核时间"
        width="160"
      >
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="120px">
        <template slot-scope="scope">
          <i
            style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="生成"
            class="el-icon-document-checked"
            @click="generate(scope.row)"
          ></i>
          <i
            style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="修改"
            class="el-icon-edit"
            @click="handleUpdate(scope.row)"
          ></i>
          <i
            style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="删除"
            class="el-icon-delete"
            @click="handleDelete(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="ToExamineParams.pageNum"
      :limit.sync="ToExamineParams.pageSize"
      @pagination="getsearchToExamine"
    />

    <!-- 生成推荐信弹窗 -->
    <el-dialog title="生成推荐信" :visible.sync="generateVisible" width="30%">
      <el-form
        :model="generateForm"
        :rules="generaterules"
        ref="generateForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="账号">
          <el-input readonly v-model="generateForm.username"></el-input>
        </el-form-item>
        <el-form-item label="推荐信模板" prop="model_id">
          <el-select
            style="width: 100%"
            v-model="generateForm.model_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in ModelDataList"
              :key="item.model_id"
              :label="item.model_name"
              :value="item.model_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CancelGenerate">取 消</el-button>
        <el-button type="primary" @click="ConfirmGenerate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改表单弹窗 -->
    <el-dialog title="生成推荐信" :visible.sync="Visible" width="80%">
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="auto"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input v-model="form.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学号" prop="student_num">
              <el-input
                type="number"
                min="0"
                v-model="form.student_num"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="form.gender" size="medium">
                <el-radio border label="0">女</el-radio>
                <el-radio border label="1">男</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平均GPA" prop="average_gpa">
              <el-input
                type="number"
                min="0"
                v-model="form.average_gpa"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="准备申请几个学校" prop="apply_school_num">
              <el-input
                type="number"
                min="0"
                v-model="form.apply_school_num"
                @change="ChangeApplySchoolNum"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="专业排名(前Top)" prop="specialist_rank">
              <el-radio-group v-model="form.specialist_rank" size="medium">
                <el-radio border label="1">1%</el-radio>
                <el-radio border label="5">5%</el-radio>
                <el-radio border label="10">10%</el-radio>
                <el-radio border label="30">10% - 30%</el-radio>
                <el-radio border label="50">30% - 50</el-radio>
                <el-radio border label="100">50 - 100%</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业名称" prop="is_credit_student">
              <!-- <el-radio-group v-model="form.is_credit_student" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group> -->
              <el-input
                placeholder="请输入专业名称"
                v-model="form.is_credit_student"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否上过我的课" prop="isin_my_class">
              <el-radio-group v-model="form.isin_my_class" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学院名称" prop="is_finance_school">
              <!-- <el-radio-group v-model="form.is_finance_school" size="medium">
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group> -->
              <el-input
                placeholder="请输入学院名称"
                v-model="form.is_finance_school"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="是否合作过研究或项目"
              prop="is_collaborate_project"
            >
              <el-radio-group
                v-model="form.is_collaborate_project"
                size="medium"
              >
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始上我的课的季度" prop="season">
              <el-select
                style="width: 100%"
                v-model="form.season"
                placeholder="请选择开始上我的课的季度"
              >
                <el-option
                  v-for="item in SeasonArr"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始上我的课的时间" prop="stu_year">
              <el-date-picker
                style="width: 100%"
                v-model="form.stu_year"
                type="year"
                format="yyyy年"
                value-format="yyyy"
                :picker-options="pickerOptions"
                placeholder="选择年"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否写课程论文" prop="is_dissert">
              <el-radio-group
                @change="IsDissertChange"
                v-model="form.is_dissert"
                size="medium"
              >
                <el-radio border label="1">是</el-radio>
                <el-radio border label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.is_dissert == 1">
            <el-form-item label="所写论文名称" prop="paper_title">
              <el-input
                :validate-event="form.is_dissert == 1 ? true : false"
                v-model="form.paper_title"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="form.apply_school_num > 0">
          <el-row v-for="(item, index) in form.apply_school_num" :key="index">
            <el-col :span="12">
              <el-form-item
                :label="'申请学校' + (index + 1)"
                :prop="`apply_schools[${index}].apply_school`"
                :rules="[
                  {
                    required: true,
                    message: '请输入申请学校',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  v-model="form.apply_schools[index].apply_school"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="'申请专业' + (index + 1)"
                :prop="`apply_schools[${index}].specialty`"
                :rules="[
                  {
                    required: true,
                    message: '请输入申请专业',
                    trigger: 'blur'
                  }
                ]"
              >
                <el-input
                  v-model="form.apply_schools[index].specialty"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文一" prop="body_text_1">
              <el-input
                autosize
                type="textarea"
                @input="descInput1"
                v-model="form.body_text_1"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal1 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文二" prop="body_text_2">
              <el-input
                type="textarea"
                autosize
                @input="descInput2"
                v-model="form.body_text_2"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal2 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="正文三" prop="body_text_3">
              <el-input
                type="textarea"
                autosize
                @input="descInput3"
                v-model="form.body_text_3"
              ></el-input>
              <span
                class="numberV"
                style="position: absolute; right: 10px; bottom: 0"
                >{{ txtVal3 }}/160</span
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="Confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  generate_letter,
  delete_submit_form,
  update_submit_form,
  search_submit_form,
  search_model
} from "../../../api/Administration.js";
export default {
  data() {
    return {
      ToExamineParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        status: "[1,2]"
      },
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      tableData: [],
      total: 0,
      ModelDataList: [], //模板数据列表
      generateVisible: false, //推荐信状态
      generateForm: {
        username: "",
        model_id: "",
        form_id: ""
      },
      generaterules: {
        model_id: [
          { required: true, message: "请选择生成模板类型", trigger: "change" }
        ]
      },
      Visible: false, //修改表单弹窗状态
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        student_num: [
          { required: true, message: "请输入学号", trigger: "blur" }
        ],
        average_gpa: [
          { required: true, message: "请输入平均GPA", trigger: "blur" }
        ],
        apply_school_num: [
          {
            required: true,
            message: "请输入准备申请几个学校",
            trigger: "blur"
          }
        ],
        body_text_1: [
          { required: true, message: "请输入准备正文一", trigger: "blur" }
        ],
        body_text_2: [
          { required: true, message: "请输入准备正文二", trigger: "blur" }
        ],
        body_text_3: [
          { required: true, message: "请输入准备正文三", trigger: "blur" }
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        season: [
          {
            required: true,
            message: "请选择开始上我的课的季度",
            trigger: "change"
          }
        ],
        stu_year: [
          {
            required: true,
            message: "请选择开始上我的课的时间",
            trigger: "change"
          }
        ],
        is_dissert: [
          {
            required: true,
            message: "请选择是否写课程论文",
            trigger: "change"
          }
        ],
        paper_title: [
          { required: true, message: "请输入所写论文名称", trigger: "blur" }
        ],
        specialist_rank: [
          {
            required: true,
            message: "请选择专业排名（前Top）",
            trigger: "change"
          }
        ],
        is_credit_student: [
          {
            required: true,
            message: "请输入专业名称",
            trigger: "blur"
          }
        ],
        isin_my_class: [
          {
            required: true,
            message: "请选择是否上过我的课",
            trigger: "change"
          }
        ],
        is_finance_school: [
          {
            required: true,
            message: "请输入学院名称",
            trigger: "blur"
          }
        ],
        is_collaborate_project: [
          {
            required: true,
            message: "请选择是否有合作过研究或项目",
            trigger: "change"
          }
        ],
        letter_type: [
          { required: true, message: "请选择推荐信类型", trigger: "change" }
        ],
        letter_form: [
          {
            required: true,
            message: "请选择需要推荐信的形式",
            trigger: "change"
          }
        ],
        apply_schools: {
          apply_school: [
            { required: true, message: "请输入申请学校", trigger: "blur" }
          ],
          specialty: [
            { required: true, message: "请输入申请专业", trigger: "blur" }
          ]
        }
      },
      options: [
        {
          label: "全部",
          val: "[1,2]"
        },
        {
          label: "未生成",
          val: "[1]"
        },
        {
          label: "已生成",
          val: "[2]"
        }
      ],
      txtVal1: 0,
      txtVal2: 0,
      txtVal3: 0,
      form: {
        apply_school_num: 1,
        apply_schools: [
          {
            apply_school: "",
            specialty: ""
          }
        ],
        letter_form: []
      },
      SeasonArr: [
        {
          val: "spring",
          label: "春"
        },
        {
          val: "summer",
          label: "夏"
        },
        {
          val: "autumn",
          label: "秋"
        },
        {
          val: "winter",
          label: "冬"
        }
      ],
      pickerOptions: {
        //disabled为函数，返回值为布尔值，
        disabledDate: time => {
          let minYear = new Date().getFullYear() - 30;
          return (
            time > Date.now() ||
            time.getTime() < new Date(JSON.stringify(minYear))
          );
        }
      }
    };
  },
  created() {
    this.getsearchToExamine();
    this.GetSearchModel();
  },
  methods: {
    // 获取模板数据列表
    GetSearchModel() {
      search_model({
        keywords: "",
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        this.ModelDataList = res.data.list;
      });
    },
    // 生成表单
    generate(item) {
      this.generateForm = {
        username: item.username,
        model_id: this.ModelDataList[0].model_id,
        form_id: item.form_id
      };
      this.generateVisible = true;
    },
    // 选择表单状态
    StatusChange(e) {
      this.getsearchToExamine();
    },
    // 选择是否写课程论文
    IsDissertChange(e) {
      if (e == 0) {
        // this.form.paper_title = "";
      }
    },
    // 取消生成推荐信
    CancelGenerate() {
      this.getsearchToExamine();
      this.generateForm = {};
      this.generateVisible = false;
    },
    // 确认生成推荐信
    ConfirmGenerate() {
      this.$refs["generateForm"].validate(valid => {
        if (valid) {
          this.$store.commit("app/CHANGE_LOADING", true);
          generate_letter({
            selection_info: this.generateForm
          })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success"
                });
                this.CancelGenerate();
                this.$store.commit("app/CHANGE_LOADING", false);
              } else {
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(err => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        } else {
          this.$store.commit("app/CHANGE_LOADING", false);
          return false;
        }
      });
    },
    // 获取为审核的表单数据列表
    getsearchToExamine() {
      search_submit_form(this.ToExamineParams).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.form_id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    // 翻译开始上我的课的季度
    translateSeason(item) {
      let Name = "";
      switch (item) {
        case "spring":
          Name = "春";
          break;
        case "summer":
          Name = "夏";
          break;
        case "autumn":
          Name = "秋";
          break;
        case "winter":
          Name = "冬";
          break;
        default:
          Name = "";
      }
      return Name;
    },
    // 修改表单
    handleUpdate(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.form.apply_school_num = JSON.parse(
        JSON.stringify(item)
      ).apply_schools.length;
      this.descInput1();
      this.descInput2();
      this.descInput3();
      this.Visible = true;
    },
    // 删除表单
    handleDelete(item) {
      this.$confirm("此操作将永久删除该表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var id;
          if (item.form_id) {
            id = [item.form_id];
          } else {
            id = this.ids;
          }
          delete_submit_form({ form_ids: id }).then(res => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.getsearchToExamine();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 选择学校功能
    ChangeApplySchoolNum(e) {
      if (e == 0) {
        this.form.apply_school_num = 1;
        return;
      } else {
        this.form.apply_school_num = Number(e);
        let CopeAll = JSON.parse(JSON.stringify(this.form.apply_schools));
        let apply_schools_obj = {
          apply_school: "",
          specialty: ""
        };
        // for(let i = 0; i < e;i++){
        CopeAll.push(apply_schools_obj);
        // }
        this.form.apply_schools = JSON.parse(JSON.stringify(CopeAll));
      }
    },
    // 取消表单修改
    Cancel() {
      this.form = {
        apply_school_num: 1,
        apply_schools: [
          {
            apply_school: "",
            specialty: ""
          }
        ],
        letter_form: []
      };
      this.Visible = false;
    },
    // 确认表单修改
    Confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          update_submit_form({
            submit_form: this.form
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.getsearchToExamine();
              this.Cancel();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 赋值文本作用域
    descInput1() {
      this.txtVal1 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal1 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    },
    descInput2() {
      this.txtVal2 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal2 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    },
    descInput3() {
      this.txtVal3 = this.form.body_text_1
        .replace(/\s/g, " ")
        .split(" ").length;
      if (this.form.body_text_1.replace(/\s/g, " ").split(" ").length >= 160) {
        this.txtVal3 = 160;
        this.form.body_text_1 = this.form.body_text_1
          .replace(/\s/g, " ")
          .split(" ")
          .slice(0, 160)
          .join(" ");
      }
    }
  }
};
</script>

<style></style>
