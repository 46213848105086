<template>
  <div class="user">
    <el-row>
      <el-col :span="4">
        <el-input
          size="mini"
          v-model="ModularParams.keywords"
          @keyup.enter.native="getsearchModular"
          clearable
          placeholder="请输入模板关键字"
        ></el-input>
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchModular"
          >搜 索</el-button
        >
      </el-col>
      <el-col :span="2" style="padding-left: 20px">
        <el-button size="mini" type="success" @click="AddUser"
          >添加模板</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%; margin-top: 20px">
      <el-table-column prop="model_name" label="模板名称"> </el-table-column>
      <el-table-column prop="letter_title" label="推荐信标题">
      </el-table-column>
      <el-table-column prop="photo_url" label="学校LOGO图像">
        <template slot-scope="scope">
          <img
            style="width: 100px; height: 30px"
            :src="scope.row.photo_url"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="signature_url" label="签名图像">
        <template slot-scope="scope">
          <img
            style="width: 100px; height: 50px"
            :src="scope.row.signature_url"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="school_address" label="学校地址">
        <template slot-scope="scope">
          <div class="cellstyle" :title="scope.row.school_address">
            {{ scope.row.school_address }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="letter_json"
        label="模型内容"
        >
      </el-table-column> -->
      <el-table-column prop="supervisor_name" label="老师名字">
      </el-table-column>
      <el-table-column prop="supervisor_email" label="老师邮箱">
      </el-table-column>
      <el-table-column prop="supervisor_phone" label="老师电话">
      </el-table-column>
      <!-- <el-table-column
        prop="supervisor_web"
        label="老师网址"
        >
      </el-table-column> -->
      <el-table-column prop="supervisor_job" label="老师职级">
        <template slot-scope="scope">
          <div class="cellstyle" :title="scope.row.supervisor_job">
            {{ scope.row.supervisor_job }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="supervisor_course"
        label="老师课程"
        >
      </el-table-column> -->
      <el-table-column prop="address" label="操作" width="100px">
        <template slot-scope="scope">
          <i
            style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="修改"
            class="el-icon-edit"
            @click="UserUpdate(scope.row)"
          ></i>
          <i
            style="
              cursor: pointer;
              padding-left: 10px;
              font-size: 18px;
              float: left;
              color: rgb(24, 144, 255);
            "
            title="删除"
            class="el-icon-delete"
            @click="UserDalete(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="ModularParams.pageNum"
      :limit.sync="ModularParams.pageSize"
      @pagination="getsearchModular"
    />

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="70%">
      <el-form ref="forms" :model="model_info" label-width="120px">
        <el-form-item label="模板名称" prop="model_name">
          <el-input v-model="model_info.model_name"></el-input>
        </el-form-item>
        <el-form-item label="推荐信标题" prop="letter_title">
          <el-input v-model="model_info.letter_title"></el-input>
        </el-form-item>
        <el-form-item label="日期格式" prop="date_type">
          <el-radio-group v-model="model_info.date_type" size="medium">
            <el-radio border label="En">英式</el-radio>
            <el-radio border label="Am">美式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="字体格式" prop="word_size">
          <el-select
            style="width:100%"
            v-model="model_info.word_size"
            placeholder="请选择字体格式"
            filterable
          >
            <el-option
              v-for="(item,index) in fontType"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字体大小" prop="font_size">
          <el-select
            style="width:100%"
            v-model="model_info.font_size"
            placeholder="请选择字体大小"
          >
            <el-option
              v-for="item in fontSize"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校LOGO图像" prop="photo_url">
          <img
            v-if="!(photo_url_Flie.length > 0) && model_info.photo_url"
            style="width: 100px"
            :src="model_info.photo_url"
            alt=""
          />
          <el-upload
            style="display: inline; text-align: left"
            action=""
            list-type="picture-card"
            :file-list="photo_url_Flie"
            :on-remove="photo_url_FlieRemove"
            :on-change="photo_url_FlieChange"
            :auto-upload="false"
          >
            <i class="el-icon-edit"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="签名图像" prop="signature_url">
          <img
            v-if="!(signature_url_Flie.length > 0) && model_info.signature_url"
            style="width: 100px"
            :src="model_info.signature_url"
            alt=""
          />
          <el-upload
            style="display: inline; text-align: left"
            action=""
            list-type="picture-card"
            :file-list="signature_url_Flie"
            :on-remove="signature_url_FlieRemove"
            :on-change="signature_url_FlieChange"
            :auto-upload="false"
          >
            <i class="el-icon-edit"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="学校地址" prop="school_address">
          <el-input v-model="model_info.school_address"></el-input>
        </el-form-item>
        <!-- <el-form-item label="模型内容" prop="letter_json">
          <el-input v-model="model_info.letter_json"></el-input>
        </el-form-item> -->
        <el-form-item label="老师名字" prop="supervisor_name">
          <el-input v-model="model_info.supervisor_name"></el-input>
        </el-form-item>
        <el-form-item label="老师邮箱" prop="supervisor_email">
          <el-input v-model="model_info.supervisor_email"></el-input>
        </el-form-item>
        <el-form-item label="老师电话" prop="supervisor_phone">
          <el-input v-model="model_info.supervisor_phone"></el-input>
        </el-form-item>
        <el-form-item label="老师网址" prop="supervisor_web">
          <el-input v-model="model_info.supervisor_web"></el-input>
        </el-form-item>
        <el-form-item label="老师职级" prop="supervisor_job">
          <el-input v-model="model_info.supervisor_job"></el-input>
        </el-form-item>
        <el-form-item label="老师课程" prop="supervisor_course">
          <el-input v-model="model_info.supervisor_course"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  add_model,
  search_model,
  update_model,
  image_upload,
  update_model_image,
  delete_model,
  search_font_type
} from "../../../api/Administration.js";
export default {
  data() {
    return {
      ModularParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10
      },
      model_info: {
        model_name: "",
        letter_title: "",
        school_address: "",
        letter_json: "",
        photo_url: "",
        signature_url: "",
        supervisor_name: "",
        supervisor_course: "",
        supervisor_email: "",
        supervisor_phone: "",
        supervisor_web: "",
        supervisor_job: ""
      },
      photo_url_Flie: [], //学校LOGO图像地址
      signature_url_Flie: [], //签名图像地址
      tableData: [],
      total: 0,
      dialogTitle: "添加模板",
      dialogVisible: false,
      fontType: [],
      fontSize: []
    };
  },
  created() {
    this.getsearchModular();
    this.searchFontType();
    this.PretreatmentfontSize();
  },
  methods: {
    // 预处理字体大小
    PretreatmentfontSize() {
      let fontSize = [];
      for (let i = 5; i <= 72; i++) {
        fontSize.push(i);
      }
      this.fontSize = fontSize;
    },
    // 获取字体数据列表
    searchFontType() {
      search_font_type().then(res => {
        this.fontType = res.font_list;
      });
    },
    // 获取用户列表数据
    getsearchModular() {
      search_model(this.ModularParams).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 移除学校LOGO图像地址
    photo_url_FlieRemove(file, fileList) {
      this.photo_url_Flie = fileList;
    },
    // 选择学校LOGO图像地址
    photo_url_FlieChange(file, fileList) {
      if (fileList.length > 1) {
        this.photo_url_Flie = fileList;
      } else {
        this.photo_url_Flie = fileList;
      }
    },
    // 移除学校LOGO图像地址
    signature_url_FlieRemove(file, fileList) {
      this.signature_url_Flie = fileList;
    },
    // 选择学校LOGO图像地址
    signature_url_FlieChange(file, fileList) {
      if (fileList.length > 1) {
        this.signature_url_Flie = fileList;
      } else {
        this.signature_url_Flie = fileList;
      }
    },
    // 取消提交
    cancel() {
      this.photo_url_Flie = [];
      this.signature_url_Flie = [];
      this.dialogTitle = "添加模板";
      this.model_info = {};
      this.getsearchModular();
      this.dialogVisible = false;
    },
    // 确认提交
    confirm() {
      // 判断表单是否有ID，如果有则为修改，否则为新增
      if (this.model_info.model_id) {
        // 判断学校LOGO图像是否进行修改选择
        if (
          this.photo_url_Flie.length > 0 &&
          this.signature_url_Flie.length > 0
        ) {
          //对学校LOGO图像和签名图像进行修改
          const p1 = new Promise((resolve, reject) => {
            var photoFlie = new FormData();
            
            photoFlie.append("file", this.photo_url_Flie[0].raw);
            photoFlie.append("id", this.model_info.model_id);
            photoFlie.append("image_name", 'photo_url');
            image_upload(photoFlie)
              .then(res => {
                if (res.code == 200) {
                  this.photo_url_Flie = [];
                  resolve(res.img_url);
                } else {
                  reject();
                }
              })
              .catch(e => {
                this.photo_url_Flie = [];
                reject();
              });
          }).then(res => res);
          const p2 = new Promise((resolve, reject) => {
            var signatureFlie = new FormData();
            
            signatureFlie.append("file", this.signature_url_Flie[0].raw);
            signatureFlie.append("id", this.model_info.model_id);
            signatureFlie.append("image_name", 'signature_url');
            image_upload(signatureFlie)
              .then(res => {
                if (res.code == 200) {
                  this.signature_url_Flie = [];
                  resolve(res.img_url);
                } else {
                  reject();
                }
              })
              .catch(e => {
                this.signature_url_Flie = [];
                reject();
              });
          }).then(res => res);

          Promise.all([p1, p2])
            .then(res => {
              let CopyForm = JSON.parse(JSON.stringify(this.model_info));
              CopyForm.photo_url = res[0];
              CopyForm.signature_url = res[1];
              update_model({ model_info: CopyForm }).then(resolve => {
                if (resolve.code == 200) {
                  this.cancel();
                }
              });
            })
            .catch(e => {});
        } else if (this.photo_url_Flie.length > 0) {
          //对学校LOGO图像进行修改
          var photoFlie = new FormData();
          photoFlie.append("file", this.photo_url_Flie[0].raw);
          photoFlie.append("id", this.model_info.model_id);
          photoFlie.append("image_name", 'photo_url');
          image_upload(photoFlie)
            .then(res => {
              if (res.code == 200) {
                this.photo_url_Flie = [];
                let CopyForm = JSON.parse(JSON.stringify(this.model_info));
                CopyForm.photo_url = res.img_url;
                update_model({ model_info: CopyForm }).then(resolve => {
                  if (resolve.code == 200) {
                    this.cancel();
                  }
                });
              }
            })
            .catch(e => {
              this.photo_url_Flie = [];
            });
        } else if (this.signature_url_Flie.length > 0) {
          //对签名图像进行修改
          var signatureFlie = new FormData();
          signatureFlie.append("file", this.signature_url_Flie[0].raw);
          signatureFlie.append("id", this.model_info.model_id);
          signatureFlie.append("image_name", 'signature_url');
          image_upload(signatureFlie)
            .then(res => {
              if (res.code == 200) {
                this.signature_url_Flie = [];
                let CopyForm = JSON.parse(JSON.stringify(this.model_info));
                CopyForm.signature_url = res.img_url;
                update_model({ model_info: CopyForm }).then(resolve => {
                  if (resolve.code == 200) {
                    this.cancel();
                  }
                });
              }
            })
            .catch(e => {
              this.signature_url_Flie = [];
            });
        } else {
          // 没对任何图片进行修改
          update_model({ model_info: this.model_info }).then(resolve => {
            if (resolve.code == 200) {
              this.cancel();
            }
          });
        }
      } else {
        // 判断是否上传学校LOGO图像
        if (!(this.photo_url_Flie.length > 0)) {
          this.$message({
            type: "info",
            message: "请选择上传学校LOGO图像"
          });
        }
        // 判断是否上传签名图像
        if (!(this.signature_url_Flie.length > 0)) {
          this.$message({
            type: "info",
            message: "请选择上传签名图像"
          });
        }
        const p1 = new Promise((resolve, reject) => {
          var photoFlie = new FormData();
          photoFlie.append("file", this.photo_url_Flie[0].raw);
          photoFlie.append("id", "");
          photoFlie.append("image_name", 'photo_url');
          image_upload(photoFlie)
            .then(res => {
              if (res.code == 200) {
                this.photo_url_Flie = [];
                resolve(res.img_url);
              } else {
                reject();
              }
            })
            .catch(e => {
              this.photo_url_Flie = [];
              reject();
            });
        }).then(res => res);
        const p2 = new Promise((resolve, reject) => {
          var signatureFlie = new FormData();
          signatureFlie.append("file", this.signature_url_Flie[0].raw);
          signatureFlie.append("id", "");
          signatureFlie.append("image_name", 'signature_url');
          image_upload(signatureFlie)
            .then(res => {
              if (res.code == 200) {
                this.signature_url_Flie = [];
                resolve(res.img_url);
              } else {
                reject();
              }
            })
            .catch(e => {
              this.signature_url_Flie = [];
              reject();
            });
        }).then(res => res);

        Promise.all([p1, p2])
          .then(res => {
            console.log(res);
            let CopyForm = JSON.parse(JSON.stringify(this.model_info));
            CopyForm.photo_url = res[0];
            CopyForm.signature_url = res[1];
            add_model({ model_info: CopyForm }).then(resolve => {
              if (resolve.code == 200) {
                this.cancel();
              }
            });
          })
          .catch(e => {});
      }
    },
    // 添加模板
    AddUser() {
      this.photo_url_Flie = [];
      this.signature_url_Flie = [];
      this.model_info.word_size = "Times New Roman"
      this.dialogTitle = "添加模板";
      this.dialogVisible = true;
    },
    // 修改模板
    UserUpdate(item) {
      this.photo_url_Flie = [];
      this.signature_url_Flie = [];
      this.model_info = JSON.parse(JSON.stringify(item));
      this.dialogTitle = "修改模板";
      this.dialogVisible = true;
      this.$forceUpdate();
    },
    // 删除模板
    UserDalete(item) {
      this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delete_model(item.model_id).then(res => {
            if (res.code == 200) {
              this.getsearchModular();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>

<style>
.cellstyle {
  height: 40px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
